/* eslint-disable vue/valid-v-slot */
<template>
  <v-container class="mt-5">
    <v-card-title>
      الإشتراكات
      <v-spacer />
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="list"
      :loading="isLoading"
    >
      <template
        v-slot:[`item.is_subscription`]="{ item }"
      >
        {{ item.is_subscription ? 'مشترك' : 'غير مشترك' }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'SubsctiptionList',
    data: () => ({
      headers: [
        { text: 'إسم المنشأة', value: 'name' },
        { text: 'الحالة', value: 'is_subscription' },
        { text: 'إسم الخطة', value: 'current_subscription.package_name_ar' },
      ],
    }),
    computed: {
      ...mapState('subscriptions', {
        list: state => state.list,
        isLoading: state => state.isLoading,
      }),
    },
    mounted () {
      this.getAllData()
    },
    methods: {
      ...mapActions('subscriptions', [
        'getAllData',
      ]),
    },
  }
</script>
